import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ButtonAppBar from "../components/ButtonAppBar";
import { ProfileForm } from "../components/ProfileForm";
import { Button, CircularProgress } from "@mui/material"; // Import CircularProgress for the loader
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";

export const Welcome: React.FC = () => {
  useEffect(() => {
    document.title = "ACTT Study";
  }, []);

  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const [redirectToHome, setRedirectToHome] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="App">
      <ButtonAppBar />
      <div style={{ padding: 20 }}>
        <img src="/logo.png" alt="ACTT Logo" style={{ maxWidth: 200 }} />
        <h2>Welcome to ACTT Study</h2>
        <ProfileForm />
      </div>
    </div>
  );
};

export default Welcome;
