import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MobileCameraView } from "../components/MobileCameraView";
import { MobileYolo } from "../components/MobileYolo"; // Import the YOLO component
import MobileYoloForm from "../components/MobileYoloForm";

export const MobileCamera: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [computedEggCount, setComputedEggCount] = useState<number | null>(null);
  const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);

  const handleImageCapture = (imageData: string | null) => {
    setCapturedImage(imageData);
  };
  return (
    <div className="MobileCameraView">
      {capturedImage ? (
        <div>
          <MobileYolo
            onImageCapture={handleImageCapture}
            capturedImage={capturedImage}
            setFileName={setFileName}
            computedEggCount={computedEggCount}
            setComputedEggCount={setComputedEggCount}
            imagesSubmitted={imagesSubmitted}
            setImagesSubmitted={setImagesSubmitted}
          />
          <MobileYoloForm
            fileName={fileName}
            computedEggCount={computedEggCount}
            imagesSubmitted={imagesSubmitted}
          />
        </div>
      ) : (
        <MobileCameraView onImageCapture={handleImageCapture} />
      )}
    </div>
  );
};

export default MobileCamera;
