import React, { useState } from "react";
import Loader from "./loader";
import CloudButtonHandler from "./CloudButtonHandler";
import "../styles/yolo.css";
import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "@mui/material";
import { BASE_URL } from "../constants/global";
import axios from "axios";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { useAuth0 } from "@auth0/auth0-react";

type fileNameProps = {
  setFileName: (arg: string) => void;
  setComputedEggCount: (arg: number) => void;
  setImageSubmitted: (arg: boolean) => void;
};

export const CloudYolo: React.FC<fileNameProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);
  const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);
  const [computedEggCount, setComputedEggCount] = useState<number>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<string | null>(null);

  const handleModalOpen = (image: string) => {
    setActiveImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);
  const handleSubmit = async () => {
    setLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const localUUID = uuidv4();
      const formData = new FormData();
      const img = new Image();
      if (imageSrc) {
        img.src = imageSrc;
      }

      const blob = await imageToBlob(img);
      formData.append("file", blob, `${localUUID}.jpg`);
      formData.append("model_name", "aedes_yolov5n");

      const response = await axios.post(`${BASE_URL}/files/forward`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      const eggCount = response.data.egg_count;
      setComputedEggCount(eggCount);

      // Update the parent component with computed egg count
      props.setComputedEggCount(eggCount); // Pass the egg count to parent

      setImageBase64(response.data.image_base64);

      setImageAnalyzed(true);
    } catch (error) {
      console.error("Error during image prediction:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const localUUID = uuidv4();

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      };

      // Upload the analyzed image
      const analyzedBlob = await imageToBlobFromBase64(imageBase64!);
      const analyzedFormData = new FormData();
      analyzedFormData.append(
        "file",
        analyzedBlob,
        `${localUUID}_analyzed.jpg`
      );
      await axios.post(`${BASE_URL}/files`, analyzedFormData, config);

      // Upload the original image
      const img = new Image();
      img.src = imageSrc!;
      const originalBlob = await imageToBlob(img);
      const originalFormData = new FormData();
      originalFormData.append("file", originalBlob, `${localUUID}.jpg`);
      await axios.post(`${BASE_URL}/files`, originalFormData, config);

      // Update the parent component with the file name
      props.setFileName(`${localUUID}.jpg`); // Pass the file name to parent

      setImagesSubmitted(true);
    } catch (error) {
      console.error("Error during image upload:", error);
    } finally {
      setIsUploading(false);
      setImagesSubmitted(true);
      props.setImageSubmitted(true);
    }
  };

  async function imageToBlobFromBase64(base64: string): Promise<Blob> {
    const response = await fetch(`data:image/jpeg;base64,${base64}`);
    const blob = await response.blob();
    return blob;
  }

  async function imageToBlob(image: HTMLImageElement): Promise<Blob> {
    if (!image.complete) {
      await new Promise((resolve) => {
        image.onload = resolve;
      });
    }

    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context?.drawImage(image, 0, 0, image.width, image.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob!);
      }, "image/jpeg");
    });
  }

  const handleClear = () => {
    setImageSrc(null);
    setImageBase64(null);
    setComputedEggCount(0); // Reset egg count if needed
    setImagesSubmitted(false); // Reset images submitted state
    setImageAnalyzed(false); // Reset analyzed state
  };

  return (
    <div>
      <div className="Aedes-view">
        <div className="header">
          <h1>Create a new record</h1>
          <h2>YOLO Cloud Model</h2>{" "}
        </div>
        <div className="image-container">
          {imageSrc && (
            <div className="image-wrapper">
              <h3>Original Image</h3>
              <img
                className="yolo-image"
                src={imageSrc || undefined}
                alt="Original Image"
                onClick={() => handleModalOpen(imageSrc!)} // Open modal with original image
              />
              {loading && (
                <div className="loader-overlay centered">
                  <div className="loader-spinner"></div>
                  <h3>Processing...</h3>
                </div>
              )}
            </div>
          )}

          {imageBase64 && (
            <div className="image-wrapper">
              <h3>Processed Image</h3>
              <img
                className="yolo-image"
                src={`data:image/jpeg;base64,${imageBase64}` || undefined}
                alt="Processed Image"
                onClick={() =>
                  handleModalOpen(`data:image/jpeg;base64,${imageBase64}`)
                } // Open modal with processed image
              />
            </div>
          )}
        </div>

        <CloudButtonHandler
          imageSrcState={[imageSrc, setImageSrc]}
          setComputedEggCount={setComputedEggCount}
          imagesSubmitted={imagesSubmitted}
          clearImage={handleClear}
        />

        {!imageAnalyzed && !loading && imageSrc && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
            style={{ margin: "10px" }}
          >
            Submit for Bounding Box Detection
          </Button>
        )}

        {imageAnalyzed && !imagesSubmitted && !isUploading && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleUpload}
            style={{ margin: "10px" }}
          >
            Upload Image with Bounding Boxes
          </Button>
        )}

        {imageAnalyzed && <h1>Egg Count {computedEggCount}</h1>}

        {isUploading && <CircularProgressWithLabel value={uploadProgress} />}
        {imagesSubmitted && <h1>Image Submitted</h1>}

        {/* Modal for larger image view */}
        <Modal open={modalOpen} onClose={handleModalClose}>
          <div className="modal-content">
            {activeImage && (
              <img className="modal-image" src={activeImage} alt="Modal View" />
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CloudYolo;
